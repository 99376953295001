import CustomButton from "../../components/Button";
import FlexBox from "../../components/FlexBox";
import Hero from "../../components/Hero";
import HeroTitle from "../../components/HeroTitle";
import PartnerCard from "../../components/PartnerCard";
import { getIsMobile } from "../../configs/mobile-config";
import Footer from "../../components/Footer";
import styles from "./home.module.css";
import CaseExperience from "../../components/CaseExperience";
import { companyConfig } from "../../configs/company-config";
import Location from "../../components/Location";
import PartnerInfoMobile from "../../components/PartnerInfoMobile";
import PartnerInfoDesktop from "../../components/PartnerInfoDesktop";
import BalanceIcon from '@mui/icons-material/Balance';
import Fee from "../../components/Fee";
import PaidIcon from '@mui/icons-material/Paid';

const HomePage = () => {

    const isMobile = getIsMobile();
    let cases;
    let partnerInfo;
    let fee;
    if(isMobile) {
        cases = <CaseExperience />
        partnerInfo = (
            <div className={styles.content}>
                <PartnerInfoMobile />
            </div>
        )
    } else {
        cases = (
            <FlexBox flexBasis="100%" justify="center" align="center" styleProps={{marginTop: "1.5rem"}}>
                <BalanceIcon fontSize="large" />
                <CaseExperience />
            </FlexBox>
        );
        partnerInfo = <PartnerInfoDesktop />
        fee = (
            <FlexBox flexBasis="100%" justify="center" align="center" styleProps={{marginTop: "1.5rem"}}>
                <FlexBox flexBasis="80%" justify="center" align="center">
                    <span><PaidIcon fontSize="large"/></span>
                    <span className={styles.title}>Fee</span>
                    <Fee />
                </FlexBox>
            </FlexBox>
        )
    }
    return (
        <div className={styles.container}>
            <Hero>
                <FlexBox flexBasis={isMobile ? "100%" : "45%"} justify="center" align="center" height="45vh">
                    <HeroTitle />
   
                    <a href={`mailto: ${companyConfig.employees.filter( x => x.id === 2 )[0].email}`}>
                        <CustomButton onClick={() => console.log("clicked")} 
                                color="#11111190" 
                                border="2px solid black" 
                                textColor="white"
                                height="3rem"
                                width="15rem"
                                fontSize="12px"
                        >
                            {companyConfig.employees.filter(x => x.id === 1)[0].email}
                        </CustomButton>
                    </a>
                    
                </FlexBox>
            </Hero>
            <FlexBox padding={getIsMobile() ? "0" : "1rem 1rem 0 1rem"} 
                        color="#efefef" 
                        width="100vw" 
                        gap="1rem" 
                        height="auto">

                <PartnerCard/>
            
                {partnerInfo}

                <Location />

                {cases}

                {fee}

            </FlexBox>

            <Footer />
        </div>
    )
}

export default HomePage;