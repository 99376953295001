import { companyConfig } from "../../configs/company-config";
import styles from "./heroTitle.module.css";

const HeroTitle = () => {
    return (
        <div className={styles.heroTitleContainer}>
            <span className={styles.heroTitleTop}>{companyConfig.name}</span>
            <div className={styles.divider}></div>
            <span className={styles.heroTitleBottom}>Mediation & Arbitration Services</span>
        </div>
    )
}

export default HeroTitle;